.navbar-link {
  transition: color 0.3s, font-weight 0.3s;
  position: relative; /* Added */
}

.navbar-link-text {
  color: #00265b;
}

.navbar-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  margin: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.navbar-link:hover {
  color: #fff;
}

.navbar-link:hover::after {
  visibility: visible;
  margin-left: 2.5%;
  width: 95%;
}
