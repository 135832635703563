.preview .image-container {
  position: relative;

  overflow: hidden;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
}

.preview .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0.01px solid rgb(200, 200, 200);
  transition: transform 0.3s ease-in-out;
}

.preview .image-container:hover {
  transform: scale(1.1);
}

.preview .image-container .overlay-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.preview .image-container:hover .overlay-text {
  opacity: 1;
}

@media (max-width: 767px) {
  .preview .image-container {
    width: 100%;
  }
}
