.clickable-image-container {
  /* cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  background-color: #d9d9d9;
  border: 0.01px solid rgb(200, 200, 200); */
  cursor: pointer;
}

@keyframes pulse {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

.skeleton {
  animation: pulse 1s infinite ease-in-out;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.modal-image {
  width: 100%;
  border-radius: 5px;
  max-height: 60vh;
  object-fit: contain;
}

.ril__captionContent {
  text-align: center !important;
}

p {
  text-align: center;
  color: #333;
  font-size: 14px;
}

.exhibit-image {
  height: 50%;
  width: 100%;
  display: block;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
}

.exhibit-image:hover {
  transform: scale(1.01);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-description {
  margin-top: 20px;
}
