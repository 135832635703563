body {
}

.headingsContainer {
  min-height: 100vh;
}

.heading-description {
  font-size: 1rem;
  color: #2c2e35;
  width: auto;
  max-height: 60vh;
  overflow: auto;
  position: relative;
  scroll-behavior: smooth;
}

.scroll-indicator {
  position: sticky;
  bottom: 0;
  height: 2rem; /* same as the padding-bottom in .heading-description */
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 0)
  );
}

.icon-container {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 24px;
  transition: transform 0.3s ease;
}

.icon-container.rotate {
  transform: rotate(180deg);
}

.heading-text {
  width: 50%;
}

.heading-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  gap: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 3vw;
  padding-right: 3vw;
}

.heading-title {
  color: #2c2e35;
  font-weight: bold;
  width: auto;
  line-height: 4vh;
  margin-top: 1vh;
}

.heading-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
  margin-top: 7vh;
}

.previewContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 20px;
  justify-content: center;
  margin: 3vh;
}

.preview-header {
  margin-left: 3vw;
  margin-right: 3vw;
}

.quote {
  font-size: 18px;
  color: #2c2e35;
  width: auto;
  line-height: 1.5;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 1vh;
  font-style: italic;
}

@media (max-width: 767px) {
  .heading-content {
    flex-direction: column-reverse;
  }
  .heading-right,
  .heading-text {
    width: 100%;
  }
  .heading-right {
    margin-top: 2vh;
  }
  .heading-content {
    gap: 1vh;
  }

  .heading-title {
    margin-bottom: 2vh;
    margin-top: 0;
  }
}
