.back-button-container {
  margin-top: 10px;
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #0f2e2e;
  color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 100; /* Ensures the button is above other page content */
}

.back-button-container:hover {
  color: white;
}

.back-button-container:hover .dropdown {
  display: block;
  color: white;
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background-color: #fff; /* White background */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 101; /* Ensures the dropdown is above the button and other page content */
}

.dropdown-item {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #ddd;
}
