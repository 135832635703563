@font-face {
  font-family: "Palatino";
  src: local("./Fonts/Palatino.ttf"),
    url("./Fonts/Palatino.ttf") format("truetype");
  font-family: "Optima";
  src: local("./Fonts/OPTIMA.TTF"), url("./Fonts/OPTIMA.TTF") format("truetype");
  font-family: "Optima Medium";
  src: local("./Fonts/Optima-Medium.ttf"),
    url("./Fonts/Optima-Medium.ttf") format("truetype");
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: "Optima";
  min-height: 100vh;
}
