.home-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-page-header {
  background-image: url("../Homepage/Images/Group\ 3.svg");
  background-position: center;
  width: 100%;
  height: 300px;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
}

.home-header-text {
  color: white;
  font-size: 2.5rem;
  padding: 1vw;
}

.home-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.home-header-photo {
  width: 100%;
  height: auto;
}

.home-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5vh;
  padding-left: 3vw;
  padding-right: 3vw;
}

.exhibit-header {
  margin-left: 3vw;
  margin-right: 3vw;
}

.home-text {
  width: 100%;
  line-height: 1.5;
}

.home-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;
}

.home-jewelry-photo {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .home-page-container {
    flex-direction: column;
  }
  .home-left {
    width: 100%;
  }
  .home-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    height: 100px;
    margin-top: 20px;
  }
}
