.image-gallery {
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-bottom: 5vh;
}

.gallery-modal-image {
  width: 100%;
  margin-top: 5%;
  border-radius: 5px;
  max-height: 60vh;
  object-fit: contain;
}

.gallery-exhibit-image {
  height: 100%;
  width: 60%;
  display: block;
  margin: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.gallery-image-container {
  margin: 10px;
  /* width: 18.5%; */
  border: 1px solid #666666;
  text-align: center;
  background-color: #e4f0ff;
  border-radius: 5px;
  margin-bottom: 40px;
}

.gallery-image {
  /* width: 100%; */
  /* height: 250px; */
  object-fit: cover;
  border-radius: 5px;
}

.image-caption {
  text-align: left;
  margin-left: 5px;
  margin-top: 5px;
  color: #333;
  font-size: 12px;
  height: auto;
  max-height: auto;
  overflow-y: auto;
}

.image {
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  object-fit: cover;
  object-position: center top;
  overflow: hidden;
}


@media (max-width: 767px) {
  .image-gallery {
    flex-direction: column;
    gap: 10px;
  }

  .gallery-image-container {
    width: 100%;
    margin: 0px;
  }
}
