.about-page-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vh;
}

.about-page-header {
  background-image: url("../Homepage/Images/Group\ 1.png");
  background-position: center;
  width: 100%;
  height: 300px;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
  margin-bottom: 5vh;
}

.about-header-text {
  color: white;
  font-size: 2.5rem;
  padding: 1vw;
}

.references {
  background-color: white;
  width: 80%;
  border: 0.5px solid #e0e0e0;
  font-size: 1.1rem;
}

.aboutHeaderImage {
  background-image: url("../../Screens/Pages/Heading-Four/heading4/subheading 4-6/4-6_photobox-2-1.jpg");
  height: 500px;
  left: -200px;
  color: white;
  justify-content: center;
  width: 100%;
  opacity: 10;
}

.accordionContainer {
  align-items: center;
  justify-content: center;
  display: flex;
}

.organization {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #e0e0e0;
  border-radius: 5px;
  width: 32vw;
}

.aboutPerson {
  width: 100%;
  margin: 0;
}

.aboutName {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.aboutRoles {
  font-size: 0.9rem;
  opacity: 0.7;
  margin: 0;
  margin-bottom: 1vh;
}

#counsel {
  margin-bottom: 1vh;
}

.twoImages {
  height: 40px;
  width: 40px;
}

.organizations {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  width: 80%;
  justify-content: center;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 5vh;
}

@media screen and (max-width: 767px) {
  .organizations {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 3vh;
  }
  .organization {
    width: 95%;
  }
  .references {
    width: 95%;
  }
}
